
* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


button {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: #f8f8f8;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
}
